import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import { SectionFaqQuestion } from 'components/section-faq-question'
import { SectionFaqGroup } from 'components/section-faq-group'
import { ContentfulPageFaq } from 'types/contentful'
import * as styles from './faq.module.css'

type DataProps = {
  contentfulPageFaq: ContentfulPageFaq
}

const FaqPage: React.FC<PageProps<DataProps>> = (props) => {
  const { layout, variant, header, heading, questions, seo } =
    props.data.contentfulPageFaq

  return (
    <Layout layout={layout} header={header} seo={seo}>
      <div className="container">
        {heading && <h1 className={styles.heading}>{heading}</h1>}
      </div>
      <div className="container">
        {(questions || []).map((question) => {
          switch (question.__typename) {
            case 'ContentfulSectionText':
              return (
                <SectionFaqQuestion
                  key={question.id}
                  question={question}
                  variant={variant}
                />
              )
            case 'ContentfulSectionFaqGroup':
              return (
                <SectionFaqGroup
                  key={question.id}
                  faqGroup={question}
                  variant={variant}
                />
              )
          }
        })}
      </div>
    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query FaqQuery($contentful_id: String!) {
    contentfulPageFaq(contentful_id: { eq: $contentful_id }) {
      contentful_id
      slug
      variant
      layout {
        ...LayoutFields
      }
      header {
        ...HeaderFields
      }
      seo {
        ...SeoFields
      }
      heading
      questions {
        __typename
        ... on ContentfulSectionText {
          ...SectionFaqQuestion
        }
        ... on ContentfulSectionFaqGroup {
          ...SectionFaqGroup
        }
      }
    }
  }
`
