import React, { useState } from 'react'
import { graphql } from 'gatsby'
import RichText from 'components/rich-text'
import caretDownLogo from 'images/caret-down.svg'
import caretUpLogo from 'images/caret-up.svg'
import { ContentfulFaqVariant, ContentfulSectionText } from 'types/contentful'
import * as styles from './section-faq-question.module.css'

type SectionFaqQuestionParams = {
  question: ContentfulSectionText
  variant: ContentfulFaqVariant
}

export const SectionFaqQuestion = ({
  question,
  variant,
}: SectionFaqQuestionParams) => {
  switch (variant) {
    case 'standard':
      return <SectionFaqQuestionStandard question={question} />
    case 'collapsed':
      return <SectionFaqQuestionCollapsed question={question} />
  }
}

const SectionFaqQuestionStandard = ({
  question,
}: {
  question: ContentfulSectionText
}) => {
  return (
    <div className={styles.standardEntry}>
      <div className={styles.standardQuestion}>{question.heading}</div>
      <div className={styles.standardAnswer}>
        {question.text && <RichText data={question.text} />}
        {question.buttonUrl && (
          <div className={styles.buttonWrapper}>
            <a className={styles.button} href={question.buttonUrl}>
              {question.buttonText}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

const SectionFaqQuestionCollapsed = ({
  question,
}: {
  question: ContentfulSectionText
}) => {
  const [expanded, setExpanded] = useState(false)

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    setExpanded((prevExpanded) => !prevExpanded)
  }

  return (
    <div className={styles.collapsedEntry}>
      <a href="#" onClick={onClick} className={styles.collapsedQuestion}>
        <span>{question.heading}</span>
        {expanded ? (
          <img src={caretUpLogo} className={styles.handle} />
        ) : (
          <img src={caretDownLogo} className={styles.handle} />
        )}
      </a>
      {expanded && (
        <div className={styles.collapsedAnswer}>
          {question.text && <RichText data={question.text} />}
          {question.buttonUrl && (
            <div className={styles.buttonWrapper}>
              <a className={styles.button} href={question.buttonUrl}>
                {question.buttonText}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  fragment SectionFaqQuestion on ContentfulSectionText {
    id
    heading
    text {
      raw
    }
    buttonText
    buttonUrl
  }
`
