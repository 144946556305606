import React from 'react'
import { graphql } from 'gatsby'
import {
  ContentfulFaqVariant,
  ContentfulSectionFaqGroup,
} from 'types/contentful'
import { SectionFaqQuestion } from './section-faq-question'
import * as styles from './section-faq-group.module.css'

type SectionFaqGroupParams = {
  faqGroup: ContentfulSectionFaqGroup
  variant: ContentfulFaqVariant
}

export const SectionFaqGroup = ({
  faqGroup,
  variant,
}: SectionFaqGroupParams) => {
  return (
    <div>
      <h2 className={styles.heading}>{faqGroup.heading}</h2>
      {(faqGroup.questions || []).map((question) => (
        <SectionFaqQuestion
          key={question.id}
          question={question}
          variant={variant}
        />
      ))}
    </div>
  )
}

export const query = graphql`
  fragment SectionFaqGroup on ContentfulSectionFaqGroup {
    id
    heading
    questions {
      ...SectionFaqQuestion
    }
  }
`
